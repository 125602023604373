/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/da3a7286fc11258e-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/18fcf849eb93dacb-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6cde8bcc1ba157c2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3301979f8786f72e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3ddd118f89855032-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d6b50e02be4f29a7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/1dfa7ee30ec54447-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/210cc95b2fda6164-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/9bc230162577e2e4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d6d55c40e86b00fc-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a88e8676a8e81296-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/23f85cf0ba24e54f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6bf9bc5cdc678b08-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/536edd72cc451926-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c7604be841aceae8-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/000325604fc3139a-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cf6f59ef74797778-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/00eecbc6c81bba79-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f29c91ab0b6ba852-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/401536ba6ac57b4b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/9b0ae27abebf40e8-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c4c946529488ae08-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3a3501e984130d11-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/1f762cf117c5b3ec-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/9e84385130e79dc9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/4772d89fe3622362-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/38a8767813a55726-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b7ab1bb36ff4f0e1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7f9085f785625122-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_Alternates_6ba7eb';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ccfaa8f4f2bbe8c6-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Montserrat_Alternates_Fallback_6ba7eb';src: local("Arial");ascent-override: 83.96%;descent-override: 21.77%;line-gap-override: 0.00%;size-adjust: 115.30%
}.__className_6ba7eb {font-family: '__Montserrat_Alternates_6ba7eb', '__Montserrat_Alternates_Fallback_6ba7eb'
}.__variable_6ba7eb {--font-montserrat-alternates: '__Montserrat_Alternates_6ba7eb', '__Montserrat_Alternates_Fallback_6ba7eb'
}

